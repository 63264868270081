import { type MessageArgsProps } from 'ant-design-vue/es/message';
import { createVNode, h } from 'vue';
import * as R from 'ramda';
import './index.less';
_message.config({
    duration: 2,
    maxCount: 1,
});

const _infoProps = {
    icon: createVNode('i', { class: 'iconfont icon-infornation', style: { color: '#056DFF' } }),
};

const _successProps = {
    icon: createVNode('i', { class: 'iconfont icon-a-yuanxinggou', style: { color: '#009C63' } }),
};

const _errorProps = {
    icon: createVNode('i', { class: 'iconfont icon-a-yuanxingcha', style: { color: '#EB4662' } }),
};

const _warningProps = {
    icon: createVNode('i', { class: 'iconfont icon-a-yuanxinggantanhao', style: { color: '#F5930A' } }),
};

const _loadingProps = {
    icon: createVNode('i', { class: 'iconfont icon-jiazai1', style: { animation: 'loading 3s linear infinite' } }),
};

const message = {
    // loading: (props: MessageArgsProps) => _message.loading(R.mergeDeepRight(props, _successProps) as MessageArgsProps),
    info: (props: MessageArgsProps | string) => {
        if (typeof props === 'string') {
            _message.info(R.mergeDeepRight({ content: props }, _infoProps) as MessageArgsProps);
        } else {
            _message.info(R.mergeDeepRight(props, _infoProps) as MessageArgsProps);
        }
    },
    success: (props: MessageArgsProps | string) => {
        if (typeof props === 'string') {
            _message.success(R.mergeDeepRight({ content: props }, _successProps) as MessageArgsProps);
        } else {
            _message.success(R.mergeDeepRight(props, _successProps) as MessageArgsProps);
        }
    },
    error: (props: MessageArgsProps | string) => {
        if (typeof props === 'string') {
            _message.error(R.mergeDeepRight({ content: props }, _errorProps) as MessageArgsProps);
        } else {
            _message.error(R.mergeDeepRight(props, _errorProps) as MessageArgsProps);
        }
    },
    warning: (props: MessageArgsProps | string) => {
        if (typeof props === 'string') {
            _message.warning(R.mergeDeepRight({ content: props }, _warningProps) as MessageArgsProps);
        } else {
            _message.warning(R.mergeDeepRight(props, _warningProps) as MessageArgsProps);
        }
    },
    loading: (props: MessageArgsProps | string) => {
        if (typeof props === 'string') {
            _message.loading(R.mergeDeepRight({ content: props }, _loadingProps) as MessageArgsProps);
        } else {
            _message.loading(R.mergeDeepRight(props, _loadingProps) as MessageArgsProps);
        }
    },
};

export default message;
